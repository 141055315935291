@mixin heading-font {
  // font-family: 'Catamaran';
  font-family: 'Dancing Script', cursive;
  font-weight: 800 !important;
}

@mixin body-font {
  font-family: 'Lato';
  // font-family: 'Playfair Display', serif;
}
