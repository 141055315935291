header
  @media (min-width: 992px)
    &.has-title
      // padding-top: calc(5rem + 55px)
      // padding-bottom: 5rem

  &.masthead
    background-image: url('images/lavender.jpeg')
    background-position: center
    background-repeat: no-repeat
    background-size: cover

    &.is-home
      padding: 0
      height: 100vh
      display: flex
      flex-direction: column
      justify-content: center

  &.bg-roses
    background-image: url('images/roses.jpeg')

  &.bg-lieu
    background-image: url('images/drome.jpg')

  &.bg-hearts
    background-image: url('images/hearts2.jpeg')

  &.bg-phone-boxes
    background-image: url('images/phone-boxes.jpg')

  &.bg-rc1
    background-image: url('images/r+c1.jpg')
    background-position: center 20%

  &.bg-rc2
    background-image: url('images/r+c2.jpg')
    background-position: center 41%

  &.bg-rchands
    background-image: url('images/r+chands.jpg')
    background-position: center 27%

.img-fluid.rounded-circle
  filter: grayscale(50%)
  mix-blend-mode: hue

.has-shadow
  text-shadow: 1px 2px 2px rgba(0,0,0,.7)

@media (max-width: 768px)
  .p-5
    padding: 1rem !important

  .display-4
    font-size: 2rem

  header.masthead
    padding-top: calc(2rem + 72px)
    padding-bottom: 2rem

    &.is-home
      height: 100vh

    .masthead-content
      margin: 0 auto
      height: 100%
      display: flex
      align-items: center

  .navbar-nav
    .nav-link
      padding: 1rem 0

a.no-style
  color: inherit

  &:hover
    text-decoration: none

.bd-callout-warning
  border-left-color: #f0ad4e

.bd-callout
  padding: 1.25rem
  margin-top: 1.25rem
  margin-bottom: 1.25rem
  border: 1px solid #eee
  border-left-width: .25rem
  border-radius: .25rem

.navigation
  padding: 15px 0
  border-top: 1px solid rgba(0,0,0,.1)
  border-bottom: 1px solid rgba(0,0,0,.1)

  .nav-link
    color: inherit
