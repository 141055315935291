.ll-header

    .nav-link
      background: none
      border: 0
      padding: 0
      color: inherit
      display: inline-block
      cursor: pointer
      color: rgba(white, .5)

      &.is-active
        color: white
