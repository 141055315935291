.bg-circles
  opacity: .3
  mix-blend-mode: color-dodge

.ember-google-map
  width: 100%
  height: 300px

body
  min-height: 100vh

  > .ember-view

    .main-content
      flex: 1
